<template>
    <div
        @click="OpenDevTool"
        id="devtool"
        style="
            width: 36px;
            height: 36px;
            position: fixed;
            bottom: 48px;
            right: -1px;
            z-index: 9999;
            /* background: red; */
            display: flex;
            text-align: right;
            justify-content: flex-end;
            align-items: center;
            color: rgb(145 145 145);
        "
    >
        .
    </div>
</template>
<script>
import eruda from "eruda";
let timeout = null;
export default {
    components: {},
    props: {},
    data() {
        return {
            count: 0,
        };
    },
    methods: {
        OpenDevTool() {
            clearTimeout(timeout);
            this.count++;
            timeout = setTimeout(() => {
                this.count = 0;
            }, 1000);
            if (this.count == 10) {
                eruda.init();
            }
        },
    },
    created() {
        // eruda.init();
    },
    mounted() {},
};
</script>
<style scoped></style>

<template>
    <ion-page>
        <ion-content :scroll-events="true">
            <div class="frame-login">
                <div class="row justify-center align-center header-style">
                    <div class="column justify-center align-center">
                        <img src="../assets/images/logo.png" alt="" class="logo" />
                        <div
                            class="font-bold pt-2"
                            style="color: #ee4426; font-size: 28px"
                        >
                            Sơn Phát C&T
                        </div>
                    </div>
                </div>

                <!-- <div id="testFCMTOk"></div> -->

                <div class="container-md content">
                    <DxValidationGroup ref="formValidation">
                        <div class="font-20 font-bold color-primary mb-3">
                            Thông tin đăng nhập
                        </div>
                        <div class="row">
                            <div class="xs12">
                                <DxTextBox
                                    label="Tài khoản (*)"
                                    labelMode="floating"
                                    v-model="model.TaiKhoan"
                                    validationMessageMode="always"
                                    styling-mode="outlined"
                                    mode="email"
                                    :tabIndex="1"
                                    ref="refTab1"
                                    :onEnterKey="onEnterKey"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Tài khoản không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="xs12">
                                <DxTextBox
                                    label="Mật khẩu (*)"
                                    labelMode="floating"
                                    v-model="model.MatKhau"
                                    validationMessageMode="always"
                                    :mode="pmMatKhau"
                                    styling-mode="outlined"
                                    class="matkhau-style"
                                    :validationStatus="success ? 'valid' : 'invalid'"
                                    :onEnterKey="dangNhap"
                                    ref="refTab2"
                                    :tabIndex="2"
                                >
                                    <DxTextBox-DxButton
                                        :options="pbMatKhau"
                                        name="password"
                                        location="after"
                                    />
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Mật khẩu không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="font-12 danger--text pt-1" v-if="!success">
                            {{ textError }}
                        </div>
                    </DxValidationGroup>
                    <div class="row mt-5">
                        <DxCheckBox
                            v-model="ghiNhoDangNhap"
                            text="Ghi nhớ tài khoản & mật khẩu"
                        />
                    </div>
                    <div class="row justify-center mt-3">
                        <ion-button
                            color="primary"
                            expand="block"
                            style="width: 100%"
                            @click="dangNhap"
                        >
                            Đăng nhập
                        </ion-button>
                    </div>
                    <div class="row justify-center font-16 font-bold footer mt-3 pb-4">
                        <!-- <div class="ml-3">
                    <router-link
                        to="/quen-mat-khau"
                        style="color: black; text-decoration: underline"
                        >Quên mật khẩu?</router-link
                    >
                </div> -->
                        <div
                            class="primary--text"
                            style="text-decoration: underline"
                            @click="$router.push('/dang-ky')"
                        >
                            Đăng ký
                        </div>
                    </div>
                </div>
            </div>
            <Devtool />
        </ion-content>
    </ion-page>
</template>

<script>
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxRequiredRule } from "devextreme-vue/data-grid";
import { DxTextBox, DxValidationGroup, DxValidator, DxButton } from "devextreme-vue";
import { IonContent, IonPage, IonButton } from "@ionic/vue";
import Devtool from "../components/_Common/Devtool.vue";
export default {
    IsFreePage: true,
    components: {
        DxCheckBox,
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxButton,
        IonButton,
        IonContent,
        IonPage,
        Devtool,
    },
    data() {
        return {
            firstTime: null,
            time: null,
            success: true,
            textError: null,
            model: {
                TaiKhoan: "",
                MatKhau: "",
            },
            pmMatKhau: "password",
            pbMatKhau: {
                icon: "mdi mdi-eye",
                type: "default",
                onClick: () => {
                    this.pmMatKhau = this.pmMatKhau === "text" ? "password" : "text";
                },
            },
            ghiNhoDangNhap: true,
            rs: null,
        };
    },
    computed: {
        percent() {
            return this.firstTime == this.time ? 0 : 100 / (this.time / 500);
        },
    },
    methods: {
        async dangNhap() {
            try {
                this.success = true;
                let valid = this.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return this.$Helper.Common.autoFocusError(valid);
                }
                this.$startLoading;
                let rs = await this.$Core.AuthApi.Login(
                    this.model.TaiKhoan,
                    this.model.MatKhau,
                    this.ghiNhoDangNhap,
                );
                if (!rs) {
                    this.success = false;
                    this.textError = "Mật khẩu không đúng!";
                }
                this.$stopLoading;
            } catch (error) {
                console.log("🚀 ~ dangNhap ~ error:", error);
                let toast = {
                    type: "error",
                    message: "Vui lòng kiểm tra kết nối!",
                    visible: true,
                };
                this.emitter.emit("onToast", toast);
                this.$stopLoading;
            } finally {
                if (this.success) {
                    let arrRequest = [];
                    // document.getElementById("testFCMTOk").innerText += `\n- 0` + navigator.userAgent;
                    if (navigator && navigator.frontendVersion) {
                        if (
                            SonPhat.setNotificationFilterConfig &&
                            typeof SonPhat.setNotificationFilterConfig === "function"
                        ) {
                            arrRequest.push(
                                SonPhat.setNotificationFilterConfig({
                                    "only.for.username": this.$Core.AuthApi.TokenParsed
                                        .preferred_username,
                                }),
                            );
                        }
                    }
                    arrRequest.push(this.$store.dispatch("ThongBao/CapNhatFcmToken"));
                    let rs = await Promise.all(arrRequest);
                    window.location.href = "/";
                }
            }
        },
        onEnterKey(e) {
            let index = e.event.currentTarget.tabIndex;
            if (index) {
                let ref = this.$refs["refTab" + (index + 1)];
                ref ? ref.instance.focus() : null;
            }
        },
    },
    mounted() {
        let refSdt = this.$refs.refTab1.instance;
        if (refSdt) {
            refSdt.focus();
        }

        let thongTinDangNhap = JSON.parse(
            localStorage.getItem("slaixe_thongTinDangNhap") || "null",
        );
        this.model.TaiKhoan = thongTinDangNhap ? thongTinDangNhap.taiKhoan : "";
        this.model.MatKhau = thongTinDangNhap ? thongTinDangNhap.matKhau : "";
        this.ghiNhoDangNhap = thongTinDangNhap ? thongTinDangNhap.ghiNhoDangNhap : false;
    },
};
</script>
<style scoped>
.frame-login {
    height: 100vh;
    overflow: scroll;
}
>>> .matkhau-style .dx-button.dx-button-default .dx-icon {
    color: black !important;
}
</style>
<style lang="scss" scoped>
.header-style {
    width: 100%;
    min-height: 230px;
    height: 230px;
    background: linear-gradient(to bottom, #08a9f278, rgb(3 169 244 / 2%));
}
.content {
    min-height: 288px;
    height: 288px;
}
.logo {
    max-width: 124px;
    max-height: 124px;
    width: 124px;
    height: 124px;
    // transition: height 0.5s;
}
// @media screen and (max-height: 500px) {
//     .header-style {
//         min-height: 0px;
//         height: 32px;
//     }
//     .content {
//         min-height: 0px;
//     }
//     .logo {
//         width: 0px;
//         height: 0px;
//     }
//     .footer {
//         display: none !important;
//     }
// }
</style>
